<template>
  <div id="BusinessSettings">
    <div class="nr-box">
      <div class="left-box">
        <div class="top-box">
          <div class="top-left">
            <p class="left-p">简报看板</p>
            <div class="left-nr">
              <div class="nr-box-1">
                <img class="img" src="../../assets/gwTP/1.png" alt="" />
                <div class="nr-1"  @click="plansummary">
                  <p class="nr-p-1">{{ totalCount['total'] }}</p>
                  <p class="nr-p-2">分配数量 </p>
                </div>
              </div>
              <div class="nr-box-1">
                <img class="img" src="../../assets/gwTP/2.png" alt="" />
                <div class="nr-1"  @click="plansummary({status:2})">
                  <p class="nr-p-1">{{ totalCount[2] }}</p>
                  <p class="nr-p-2">回访数量</p>
                </div>
              </div>
              <div class="nr-box-1">
                <img class="img" src="../../assets/gwTP/3.png" alt="" />
                <div class="nr-1"  @click="plansummary({status:0})">
                  <p class="nr-p-1">{{ totalCount[0] }}</p>
                  <p class="nr-p-2">未完成数量</p>
                </div>
              </div>
              <div class="nr-box-1">
                <img class="img" src="../../assets/gwTP/4.png" alt="" />
                <div class="nr-1"  @click="plansummary">
                  <p class="nr-p-1">{{ orderCount.planCount }}</p>
                  <p class="nr-p-2">订单数量</p>
                </div>
              </div>
            </div>
          </div>
          <div class="top-right">
            <p style="margin-top: 30px; margin-bottom: 30px">
              <samp style="margin: 0 62px 0 20px; font-size: 18px; color: #434343">任务汇总</samp><span class="point"
                style="color: #ff7e00; font-size: 16px" @click="plansummary">详细情况</span>
            </p>
            <div class="nr-box-1 ">
              <img class="img" src="../../assets/gwTP/5.png" alt="" />
              <div class="nr-1">
                <p class="nr-p-1">{{ totalCount[2] }}</p>
                <p class="nr-p-2">完成总量</p>
              </div>
            </div>
          </div>
        </div>

        <div class="nr-z-box">
          <div class="nr-z-left">
            <p class="left-top-p">今日任务</p>
            <ul class="z-left-ul">
              <li class="z-left-li">
                <p style="color: #ff7e00; margin-bottom: 12px; font-size: 18px">
                  {{ todayCount['total'] }}
                </p>
                <p>今日联系</p>
              </li>
              <li class="z-left-li">
                <p style="color: #67c23a; margin-bottom: 12px; font-size: 18px">
                  {{ todayCount[2] }}
                </p>
                <p>已完成</p>
              </li>
              <li class="z-left-li">
                <p style="color: #f56c6c; margin-bottom: 12px; font-size: 18px">
                  {{ todayCount[0] }}
                </p>
                <p>未完成</p>
              </li>
              <li @click="plansummary" style="display: flex; align-items: center;cursor:pointer;">
                <img style="width: 28px; height: 28px" src="../../gwTP/6.png" alt="" />
              </li>
            </ul>
          </div>
          <div class="nr-z-right">
            <p class="z-right-p">已完成订单</p>
            <ul class="z-right-ul">
              <li class="z-right-li">
                <img style="width: 28px; height: 28px; align-self: center" src="../../assets/gwTP/7.png" alt="" />
                <div class="li-nr">
                  <p style="font-size: 18px; color: #333333"> {{ orderCount.recordPrice }} </p>
                  <p style="color: #808080; font-size: 14px">订单金额</p>
                </div>
              </li>
              <li class="z-right-li">
                <img style="width: 28px; height: 28px; align-self: center" src="../../assets/gwTP/8.png" alt="" />
                <div class="li-nr">
                  <p style="font-size: 18px; color: #333333">{{ orderCount.recordCount }}</p>
                  <p style="color: #808080; font-size: 14px">订单数量</p>
                </div>
              </li>
            </ul>
          </div>
        </div>

        <div class="nr-x-box">
          <div class="nr-x-left">
            <p style="
                display: flex;
                padding: 30px 50px 18px 20px;
                justify-content: space-between;
              ">
              <samp style="color: #434343; font-size: 18px">课程表</samp><samp style="color: #ff7e00; font-size: 18px"
                @click="Morecourses">查看更多</samp>
            </p>
            <div>
              <el-table ref="multipleTable" :data="tableData" tooltip-effect="dark">
                <el-table-column prop="start_time" label="上课时间"> </el-table-column>
                <el-table-column prop="" label="上课用户">
                  <template v-slot="{ row }">
                    <link-to :to="{ name: 'userDetails', params: { id: row.user_id } }">{{ row.map.userinfo.nick_name
                    }}</link-to>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </div>
          <div class="nr-x-right">
            <p class="x-right-p">遗忘提醒</p>
            <ul class="x-right-ul">
              <li @click="planEndList(1)" class="x-right-li">
                <p class="x-right-p1">一周内未联系(7天内)</p>
                <p class="x-right-p2">{{ endCount["oneWeek"] }}</p>
              </li>
              <li @click="planEndList(2)" class="x-right-li">
                <p class="x-right-p1">一到两周未联系(7-14天)</p>
                <p class="x-right-p2">{{ endCount["twoWeek"] }}</p>
              </li>
              <li @click="planEndList(3)" class="x-right-li">
                <p class="x-right-p1">两周到一个月内未联系(14-30天)</p>
                <p class="x-right-p2">{{ endCount["oneMonth"] }}</p>
              </li>
              <li @click="planEndList(4)" class="x-right-li">
                <p class="x-right-p1">一个月以上未联系(30天+)</p>
                <p class="x-right-p2">{{ endCount["monthMore"] }}</p>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div class="right-box">
        <p style="color: #434343; margin: 30px 0 20px 20px; font-size: 18px">
          回访计划
        </p>
        <div style="padding: 0 50px">
          <caleden @change="getTime"></caleden>

          <div style="
              display: flex;
              justify-content: space-between;
              height: 50px;
              align-items: center;
              border-bottom: 1px solid #eaeaea;
              margin-bottom: 20px; ;
            ">
            <span style="color: #434343; font-size: 16px">计划安排({{ total }}) <span class="link" @click="plansummary">全部</span></span>
            <samp style="font-size: 16px; color: #ff7e00" @click="openAddPlan">添加计划</samp>
          </div>
          <el-empty v-if="planList.length == 0" :image-size="200" description="暂无计划"></el-empty>
          <el-timeline>
            <el-timeline-item placement="top" v-for="(plan, index) in planList" :key="index" color="#FF7E00"
              :timestamp="plan.start_time">
              <div>
                <div class="flex">
                  用户： <link-to v-if="plan.type == 1" :to="{ name: 'userDetails', params: { id: plan.user_id } }">{{
                    plan.user_name }}</link-to>
                  <link-to v-if="plan.type == 2" :to="{ name: 'mecDetails', query: { mecID: plan.user_id } }">{{
                    plan.user_name }}</link-to>
                  <span v-if="plan.type == 3">{{ plan.user_name }}</span>
                </div>
                <div>
                  执行人： {{ plan.admin_name }}
                </div>
                <div>
                  <textCollapse :data="'计划：' + plan.content"></textCollapse>
                </div>
                <div>
                  状态： <span :style="{ color: planStatus[plan.status].color }">{{ planStatus[plan.status].name }}</span>
                </div>
                <div v-if="plan.status != 2">
                  操作： <span class="sbtn" @click="openRecord(plan)">完成计划</span>
                  <span class="sbtn" @click="cancelPlan(plan, 0)"
                    v-if="plan.add_admin_id == manager.id && plan.status === 1">开启计划</span>
                  <span class="sbtn" @click="cancelPlan(plan, 1)"
                    v-if="plan.add_admin_id == manager.id && plan.status === 0">取消计划</span>
                  <span class="sbtn" @click="openUpdatePlan(plan)" v-if="plan.add_admin_id == manager.id">修改</span>
                  <span class="sbtn" @click="deletePlan(plan)" v-if="plan.add_admin_id == manager.id">删除</span>
                </div>
              </div>
            </el-timeline-item>
          </el-timeline>
        </div>
      </div>

      <!-- 回访计划 -->
      <el-dialog :visible.sync="dialogVisible" width="30%" title="添加计划" :destroy-on-close="true">
        <el-form label-width="80px">
          <el-form-item label="回访对象" required>
            <el-radio v-model="planForm.type" :label="1">注册用户</el-radio>
            <el-radio v-model="planForm.type" :label="2">入驻机构</el-radio>
            <el-radio v-model="planForm.type" :label="3">其他(待入驻)</el-radio>
          </el-form-item>
          <el-form-item label="访问用户" required v-if="planForm.type == 1">
            <user-select @change="changeUser" ref="user" :placeholder="planForm.user_name"></user-select>
          </el-form-item>
          <el-form-item label="访问机构" required v-if="planForm.type == 2">
            <mechanism-select @change="changeMec" ref="user" :placeholder="planForm.user_name"></mechanism-select>
          </el-form-item>
          <el-form-item label="访问对象" required v-if="planForm.type == 3">
            <el-input v-model="planForm.user_name" placeholder="请输入访问对象"></el-input>
          </el-form-item>
          <el-form-item label="计划内容" required>
            <el-input type="textarea" :rows="2" v-model="planForm.content" placeholder="请输入计划内容"></el-input>
          </el-form-item>
          <el-form-item label="执行人" ref="admin" required v-if="manager.type == 'admin'">
            <div class="flex">  <admin-select ref="as" @change="changeConsultant" :placeholder="planForm.admin_name"></admin-select>
            <el-button type="text" @click="changeSelf">本人</el-button></div>
          </el-form-item>
          <el-form-item label="开始时间" required>
            <el-date-picker v-model="planForm.start_time" value-format="yyyy-MM-dd HH:mm:ss" type="datetime"
              placeholder="选择日期时间">
            </el-date-picker>
          </el-form-item>
          <el-form-item label="结束时间">
            <el-date-picker v-model="planForm.end_time" value-format="yyyy-MM-dd HH:mm:ss" type="datetime"
              placeholder="选择日期时间">
            </el-date-picker>
          </el-form-item>
          <el-form-item label="订单">
            <el-switch v-model="planForm.has_order" :active-value="1" :inactive-value="0" active-text="有订单"
              inactive-text="无">
            </el-switch>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button v-if="mode == 'add'" style="background-color: #ff7e00; color: #ffffff" @click="addplan">确
            定</el-button>
          <el-button v-else style="background-color: #ff7e00; color: #ffffff" @click="updateplan">修 改</el-button>
        </span>
      </el-dialog>

      <!-- 完成计划 -->
      <el-dialog :visible.sync="recordShow" width="30%" title="完成计划">
        <el-form label-width="80px">
          <el-form-item label="联系方式" required>
            <el-radio v-model="recordForm.contact_type" :label="1">电话</el-radio>
            <el-radio v-model="recordForm.contact_type" :label="2">微信</el-radio>
          </el-form-item>
          <el-form-item label="联系内容"  required>
            <el-input type="textarea" :autosize="{ minRows: 2, maxRows: 6 }" placeholder="请输入内容"
              v-model="recordForm.contact_content">
            </el-input>
          </el-form-item>
          <el-form-item label="联系时间" required>
            <el-date-picker v-model="recordForm.contact_time" value-format="yyyy-MM-dd HH:mm:ss" type="datetime"
              placeholder="选择日期时间">
            </el-date-picker>
          </el-form-item>
          <el-form-item label="订单金额" required v-if="curPlan.has_order">
            <el-input placeholder="请输入订单金额" v-model="recordForm.order_price">
            </el-input>
          </el-form-item>
          <el-form-item label="订单完成图片" required v-if="curPlan.has_order">
            <images-uploader @getImgUrl="handlerOrderImg"></images-uploader>
          </el-form-item>
          <el-form-item label="订单备注" v-if="curPlan.has_order">
            <el-input type="textarea" :autosize="{ minRows: 2, maxRows: 6 }" placeholder="请输入订单备注"
              v-model="recordForm.order_remark">
            </el-input>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button style="background-color: #ff7e00; color: #ffffff" @click="addRecord">确 定</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import caleden from "./components/caleden.vue";
import textCollapse from "@/components/text/textCollapse.vue";
import adminSelect from "@/components/select/adminSelect.vue"

export default {
  components: {
    caleden,
    textCollapse,
    adminSelect
  },
  data() {
    return {
      nav: {
        firstNav: "",
      },
      dialogVisible: false,
      tableData: [],
      tablePage: 1,
      tableTotal: 0,
      planForm: {},
      planList: [],
      manager: JSON.parse(localStorage.getItem("managerInfo")),
      currentPage: 1,
      total: 0,
      planStatus: [{
        color: "#f0924e",
        name: '待完成'
      }, {
        color: "#585858",
        name: '已取消'
      }, {
        color: "#4f9b2c",
        name: '已完成'
      }],
      planStartTime: new Date().Format("yyyy-MM-dd") + ' 00:00:00',
      planEndTime: new Date().Format("yyyy-MM-dd") + ' 23:59:59',
      mode: 'add',
      //完成计划，联系记录
      recordForm: {},
      recordShow: false,
      curPlan: {},

      totalCount: {},
      todayCount: {},
      endCount: {},
      orderCount:{}
    };
  },
  created() {
    this.getPlanList()
    this.getUserAppointmentList()
    this.getPlanCount()
    this.getPlanCountToday()
    this.getPlanEndCount()
    this.getOrderCount()
  },
  methods: {
    changeSelf(){
      this.$refs.as.selected_admin= this.manager.name
      this.planForm.admin_name = this.manager.name
      this.planForm.admin_id = this.manager.id
    },
    //状态统计
    getOrderCount() {
      this.$axios({
        url: '/user/consultantPlan/orderCount',
        method: 'get',
        params:{
          admin_id: this.manager.id
        }
      }).then(res => {
        this.orderCount = res.data.data
      })
    },
    //状态统计
    getPlanCount() {
      this.$axios({
        url: '/user/consultantPlan/countStatus',
        method: 'get',
        params: {
          admin_id: this.manager.id
        }
      }).then(res => {
        this.totalCount = res.data.data
        this.totalCount.total = this.totalCount[0] + this.totalCount[2]
      })
    },
    //遗忘统计
    getPlanEndCount() {
      this.$axios({
        url: '/user/consultantPlan/endCount',
        method: 'get',
        params: {
          admin_id: this.manager.id
        }
      }).then(res => {
        this.endCount = res.data.data
      })
    },
    //今日任务统计
    getPlanCountToday() {
      this.$axios({
        url: '/user/consultantPlan/countStatus',
        method: 'get',
        params: {
          start_time: new Date().Format("yyyy-MM-dd") + ' 00:00:00',
          end_time: new Date().Format("yyyy-MM-dd") + ' 23:59:59',
          admin_id: this.manager.id

        }
      }).then(res => {
        this.todayCount = res.data.data
        this.todayCount.total = this.todayCount[0] + this.todayCount[2]

      })
    },

    //完成记录
    openRecord(plan) {
      this.curPlan = plan
      this.recordShow = true
    },
    addRecord() {
      if (this.checkRecordForm()) {
        let data = {
          admin_id: this.curPlan.admin_id,
          admin_name: this.curPlan.admin_name,
          user_id: this.curPlan.user_id,
          user_name: this.curPlan.user_name,
          plan_id: this.curPlan.id,
          contact_content: this.recordForm.contact_content,
          contact_time: this.recordForm.contact_time,
          contact_type: this.recordForm.contact_type,
          order_price: this.recordForm.order_price,
          order_img: this.recordForm.order_img,
          order_remark: this.recordForm.order_remark
        }
        this.$axios({
          url: '/user/consultantContactRecord/insert',
          data: data,
          method: 'post'
        }).then(res => {
          this.$notify.success('计划已完成')
          this.recordForm = {}
          this.recordShow = false
          this.getPlanList()
        })
      }
    },


    openAddPlan() {
      this.$nextTick(() => {
        this.$refs.user.clear()
        this.$refs.admin.clear()
      });
      this.dialogVisible = true
      this.mode = 'add'
    },
    openUpdatePlan(plan) {
      this.mode = 'edit'
      this.$nextTick(() => {
        this.$refs.user.clear()
        this.$refs.admin.clear()
      });
      this.dialogVisible = true
      this.planForm = plan
    },
    cancelPlan(plan, status) {
      let t = status == 1 ? '取消' : '开启'
      this.$confirm(`·确认${t}回访计划?`, `${t}计划`).then(() => {
        this.$axios({
          url: '/user/consultantPlan/update',
          data: {
            status,
            id: plan.id
          },
          method: 'post'
        }).then(res => {
          this.$message(`·${t}成功`)
          this.getPlanList()
        })
      })
    },
    deletePlan(plan) {
      this.$confirm("确认删除回访计划？", "删除计划").then(() => {
        this.$axios({
          url: '/user/consultantPlan/delete',
          params: {
            id: plan.id
          },
        }).then(res => {
          this.$message('任务已删除')
          this.getPlanList()
        })
      })
    },
    changeUser(user) {
      this.planForm.user_id = user.user_id
      this.planForm.user_name = user.nick_name
    },
    changeMec(mec) {
      this.planForm.user_id = mec.id
      this.planForm.user_name = mec.mechanism_name
    },
    changeConsultant(admin) {
      this.planForm.admin_id = admin.id
      this.planForm.admin_name = admin.name
    },
    handlerOrderImg(v) {
      this.recordForm.order_img = v
    },
    addplan() {
      if (this.manager.type == 'consultant') {
        this.planForm.admin_id = this.manager.id
        this.planForm.admin_name = this.manager.name
      }
      if (this.checkForm()) {
        this.planForm.add_admin_id = this.manager.id
        this.planForm.add_admin_name = this.manager.name
        this.$axios({
          url: '/user/consultantPlan/insert',
          data: this.planForm,
          method: 'post'
        }).then(res => {
          this.planForm = {}
          this.dialogVisible = false
          this.getPlanList()
        })
      }
    },
    updateplan() {
      if (this.manager.type == 'consultant') {
        this.planForm.admin_id = this.manager.id
        this.planForm.admin_name = this.manager.name
      }
      if (this.checkForm()) {
        let data = {
          id: this.planForm.id,
          user_id: this.planForm.user_id,
          user_name: this.planForm.user_name,
          admin_id: this.planForm.admin_id,
          admin_name: this.planForm.admin_name,
          content: this.planForm.content,
          start_time: this.planForm.start_time,
          end_time: this.planForm.end_time
        }
        this.$axios({
          url: '/user/consultantPlan/update',
          data: data,
          method: 'post'
        }).then(res => {
          this.planForm = {}
          this.dialogVisible = false
          this.getPlanList()
        })
      }
    },
    getPlanList() {
      let data = {
        start_time: this.planStartTime,
        end_time: this.planEndTime,
        pageSize: 10,
        currentPage: this.currentPage,
      }
      if (this.manager.type == 'consultant') {
        data.admin_id = this.manager.id
      } else {
        data.add_dmin_id = this.manager.id
      }
      this.$axios({
        url: '/user/consultantPlan/queryManagerListPage',
        params: data,
      }).then(res => {
        this.planList = res.data.data.rows
        this.total = res.data.data.total
      })
    },
    getUserAppointmentList() {
      this.$axios({
        url: '/user/consultantRelative/queryUserAppointment',
        params: {
          admin_id: this.manager.id,
          start_time: new Date().Format("yyyy-MM-dd") + " 00:00:00",
        },
      }).then(res => {
        this.tableData = res.data.data.rows
        this.tableTotal = res.data.data.total
      })
    },
    getTime(v) {
      this.planStartTime = v.time + ' 00:00:00'
      this.planEndTime = v.time + ' 23:59:59'
      this.getPlanList()
    },
    handleClose() { },
    plansummary(params) {
      this.$router.push({
        name: "planList",
        params:params
      });
    },
    planEndList(type) {
      this.$router.push({
        name: "planEndList",
        query: { type }
      });
    },
    Morecourses() {
      this.$router.push({
        name: "Morecourses",
      });
    },
    checkForm() {
      if (!this.planForm.user_name) {
        this.$message("请选择访问用户")
        return false
      }
      if (!this.planForm.admin_name) {
        this.$message("请选择执行顾问")
        return false
      }
      if (!this.planForm.content) {
        this.$message("请输入内容")
        return false
      }
      if (!this.planForm.start_time) {
        this.$message("请选择开始时间")
        return false
      }
      return true
    },
    checkRecordForm() {
      if (!this.recordForm.contact_type) {
        this.$message("请选择联系方式")
        return false
      }
      if (!this.recordForm.contact_time) {
        this.$message("请选择联系时间")
        return false
      }
      if (!this.recordForm.contact_content) {
        this.$message("请输入内容")
        return false
      }
      if (this.curPlan.has_order == 1 && !this.recordForm.order_img) {
        this.$message("请上传订单图片，如合同，收款凭证")
        return false
      }
      if (this.curPlan.has_order == 1 && !this.recordForm.order_price) {
        this.$message("请输入订单金额")
        return false
      }
      return true
    },
  },
};
</script>

<style lang="less">
#BusinessSettings {
  .nr-box {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    background-color: transparent;

    .left-box {
      width: 65%;
      margin-right: 20px;

      @media screen and(max-width: 1280px) {
        width: 100%;
        margin-right: 0px;
      }

      .top-box {
        width: 100%;
        display: flex;
        flex-wrap: wrap;

        @media screen and(max-width: 1280px) {
          height: auto;
        }

        .top-left {
          margin-right: 20px;
          width: 70%;
          background-color: #ffffff;
          padding-bottom: 30px;

          @media screen and(max-width: 1280px) {
            width: 100%;
            margin: 0;
            margin-bottom: 20px;
          }

          .left-p {
            padding: 30px 0 30px 20px;
            color: #434343;
            font-size: 18px;
          }

          .left-nr {
            padding: 0 20px 0 50px;
            display: flex;
            flex-wrap: wrap;

            .nr-box-1 {
              display: flex;
              flex: 1;

              .img {
                width: 48px;
                height: 48px;
              }

              .nr-1 {
                padding-left: 15px;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                cursor:pointer;
                .nr-p-1 {
                  font-size: 18px;
                  color: #333333;
                }

                .nr-p-2 {
                  font-size: 14px;
                  color: #808080;
                }
              }
            }
          }
        }

        .top-right {
          flex: 1;
          background-color: #ffffff;
          border-left: 4px solid #ff7e00;
          padding-bottom: 30px;

          .nr-box-1 {
            display: flex;
            padding-left: 50px;
            flex: 1;

            .img {
              width: 48px;
              height: 48px;
            }

            .nr-1 {
              padding-left: 15px;
              display: flex;
              flex-direction: column;
              justify-content: space-between;

              .nr-p-1 {
                font-size: 18px;
                color: #333333;
              }

              .nr-p-2 {
                font-size: 14px;
                color: #808080;
              }
            }
          }
        }
      }

      .nr-z-box {
        margin-top: 20px;
        display: flex;
        flex-wrap: wrap;

        .nr-z-left {
          width: 56%;
          margin-right: 20px;

          @media screen and(max-width: 1280px) {
            width: 100%;
            margin: 0px;
            margin-bottom: 20px;
          }

          .left-top-p {
            padding: 30px 0 30px 24px;
            color: #434343;
            font-size: 18px;
          }

          background-color: #ffffff;

          .z-left-ul {
            display: flex;
            padding: 0 50px 30px 50px;

            .z-left-li {
              flex: 1;
              text-align: center;
              color: #808080;
              position: relative;
            }

            .z-left-li+.z-left-li::before {
              content: "";
              position: absolute;
              display: block;
              width: 1px;
              height: 20px;
              background-color: #E9E9E9;
              top: 16px;
            }
          }

        }

        .nr-z-right {
          flex: 1;
          background-color: #ffffff;

          .z-right-p {
            padding: 30px 0 34px 20px;
            color: #434343;
            font-size: 18px;
          }

          .z-right-ul {
            display: flex;
            padding: 0 50px 30px 50px;
            justify-content: space-between;

            .z-right-li {
              width: 48px;
              height: 48px;
              flex: 1;
              display: flex;
              justify-content: center;

              .li-nr {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                margin-left: 10px;
              }
            }
          }
        }
      }

      .nr-x-box {
        margin-top: 20px;
        display: flex;
        flex-wrap: wrap;

        .nr-x-left {
          margin-right: 20px;
          width: 40%;
          height: 330px;
          background-color: #ffffff;

          @media screen and(max-width: 1280px) {
            margin-right: 0;
            width: 100%;
            margin-bottom: 20px;
          }
        }

        .nr-x-right {
          flex: 1;
          background-color: #ffffff;
          min-height: 330px;

          .x-right-p {
            color: #434343;
            padding: 27px 0 28px 20px;
            font-size: 18px;
          }

          .x-right-ul {
            display: flex;
            flex-wrap: wrap;
            padding: 0 30px 0 10px;

            .x-right-li {
              min-width: 200px;
              height: 100px;
              flex: 1;
              border: 1px solid #eaeaea;
              margin-left: 20px;
              margin-bottom: 20px;
              padding-left: 15px;
              cursor: pointer;

              .x-right-p1 {
                margin: 20px 0 25px 0;
                color: #707070;
                font-size: 16;
              }

              .x-right-p2 {
                color: #333333;
                font-size: 16;
              }

              &:hover .x-right-p2 {
                font-size: 18px;
              }
            }
          }
        }
      }
    }

    .right-box {
      flex: 1;
      background-color: #ffffff;

      @media screen and(max-width: 1280px) {
        margin-top: 20px;
      }
    }
  }
}

.sbtn {
  cursor: pointer;
  margin-right: 15px;
  color: #0090ff;
}

// .el-tabs__header{
//     margin: 0 !important;
// }
</style>
