<template>
  <div class="cale">
    <div class="top flex al">
      <div>{{ new Date(current).format("yyyy-MM").replace("-", "年") }}月</div>
      <div class="flex al">
        <i class="el-icon-arrow-left icon" @click="preWeek"></i>
        <i class="el-icon-arrow-right icon" @click="nextWeek"></i>
        <i class="el-icon-arrow-down icon" @click="cshow = true"></i>
      </div>
    </div>
    <div class="date">
      <div class="flex al j-c-b">
        <div class="day" v-for="item in week" :key="item.id">
          {{ item.name }}
        </div>
      </div>
      <div class="flex al j-c-b">
        <div
          class="day"
          v-for="item in curWeek"
          :key="item.week"
          :class="currentDate == item.time ? 'active' : ''"
          @click="changeTime(item)"
        >
          {{ item.day }}
        </div>
      </div>
    </div>
    <div class="elcale" v-show="cshow">
      <el-calendar>
        <template slot="dateCell" slot-scope="{ data }">
        <p class="flex al p-time" @click.stop="gettime(data)" :class="data.isSelected ? 'is-selected' : ''">
            {{ data.day.split("-")[2] }}
          </p>
         
        </template>
      </el-calendar>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      cshow: false,
      value: "",
      week: [
        { id: 1, name: "一" },
        { id: 2, name: "二" },
        { id: 3, name: "三" },
        { id: 4, name: "四" },
        { id: 5, name: "五" },
        { id: 6, name: "六" },
        { id: 0, name: "日" },
      ],
      curWeek: [],
      currentDate: new Date().format("yyyy-MM-dd"),
      current: new Date().getTime(),
    };
  },
  created() {
    this.curWeek = this.getNowWeek(this.current);
  },

  methods: {
    // 获取本周
    getNowWeek(s) {
      let curDay = s ? new Date(s).getDay() : new Date().getDay();
      let start = s ? new Date(s).getTime() : new Date().getTime();
      let week = [];
      if(curDay===0){
        curDay = 7
      }
      for (let i = 1-curDay; i < 8-curDay; i++) {
        let time = new Date(start + i * 1000 * 24 * 60 * 60);
        let data = {
          week: time.getDay(),
          day: time.getDate(),
          time: time.format("yyyy-MM-dd"),
        };
        if(data.week===0){
        week[6] = data;
        }else{
          week[data.week-1] = data;
        }
      }
      return week;
    },
    changeTime(item) {
      this.currentDate = item.time;
      this.$emit("change", item);
    },
    //上周
    preWeek() {
      this.current = new Date(Number(this.current) - 7 * 1000 * 24 * 60 * 60);
      this.curWeek = this.getNowWeek(this.current);
    },
    //下周
    nextWeek() {
      this.current = new Date(Number(this.current) + 7 * 1000 * 24 * 60 * 60);
      this.curWeek = this.getNowWeek(this.current);
    },
    //日历点击事件
    gettime(t) {
    console.log(t);
    this.currentDate=t.day
      this.cshow = false;
      let time =new Date(t.day)
      this.current =time.getTime()
      this.curWeek=this.getNowWeek( this.current)
      let data = {
          week: time.getDay(),
          day: time.getDate(),
          time: time.format("yyyy-MM-dd"),
        };
        this.$emit("change", data);
    },
  },
};
</script>
<style scoped lang="less">
.flex {
  display: flex;
}
.al {
  align-items: center;
}
.j-c-b {
  justify-content: space-between;
}
.top {
  border-bottom: 1px solid #eaeaea;
  height: 40px;
  justify-content: space-between;
  font-size: 16px;
}
.cale {
  position: relative;
}
.icon {
  font-size: 20px;
  margin-left: 30px;
  font-weight: 600;
}
.date {
  font-size: 16px;
  color: #434343;
  padding: 10px 0;
  border-bottom: 1px solid #eaeaea;
}
.day {
  width: 40px;
  height: 40px;
  text-align: center;
  line-height: 40px;
  cursor:pointer;
}
.active {
  background-color: #ff7e00;
  color: #fff;
  border-radius: 5px;
}
.elcale {
  position: absolute;
  background-color: #fff;
  z-index: 100;
  top: 50px;
}
.p-time{
    width: 100%;
    height: 100%;
}
.is-selected{
    color:#ff7e00;
}
</style>